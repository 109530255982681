import React from "react"
import { Container } from "semantic-ui-react"

import Layout from "../components/layout"
import SEO from "../components/Seo"
import Header from "../components/Header"
import Decoration from "../components/Decoration"

const SupportPage = () => (
  <Layout id="pages-dark">
    <SEO title="Support" description="Support offers all the help you need in order to get help from the community regarding technical issues or otherwise, please join our Discord to get access to the community" />
    <Header active="support" />
    <Container text>
      <Decoration content="Support" />
      <p>
        Join our Discord server and ask for help in our <a
          className="comm-link"
          href="https://discord.gg/0Xeb4YQwnUVhxisY"
          rel="noopener noreferrer"
          target="_blank"
          title="Join us on Discord"
        >#help-and-support channel</a>.
        There's lots of people there who can help.
        </p>
    </Container>
  </Layout>
)

export default SupportPage
